
/*
|--------------------------------------------------------------------------
| Partials
|--------------------------------------------------------------------------
|
| Split the application code to several files. Almost all of the following
| files are required for the application to work properly.
|
*/
require('../partials/config.js');
require('../partials/bind.js');
require('../partials/drawer.js');
require('../partials/font.js');
require('../partials/form.js');
// require('../partials/mailer.js'); //Handles PHP mail sending
// require('../partials/map.js'); //Handles google maps
// require('../partials/modal.js'); //Handles modals
require('../partials/navbar.js');
// require('../partials/offcanvas.js'); //Handles offcanvas
// require('../partials/popup.js'); //Handles popups
// require('../partials/recaptcha.js'); //Handles recaptcha
require('../partials/scroll.js');
require('../partials/section.js'); //File is empty
require('../partials/sidebar.js');
// require('../partials/video.js');//Handles video
require('../partials/util.js');
